import { useNavigate } from '@hooks/useNavigate';
import categoryQaData from '@values/questions-answers';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQASpecificContext } from './QASpecificProvider';
import { useHandleLinkClick } from './useHandleLinkClick';

function useComponentState() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { question } = useQASpecificContext();
  useHandleLinkClick();

  const answerKey = getAnswerKey(categoryQaData, question, navigate);
  const answer = t(answerKey);

  return {
    answer,
  };
}

export const QASpecificAnswer = () => {
  const { answer } = useComponentState();

  return <div className="q-answer basis-full text-xs lg:text-base" dangerouslySetInnerHTML={{ __html: answer }}></div>;
};

function getAnswerKey(categories, question, navigate) {
  const category = categories.find((v) => v.list.some((v2) => v2.question === question));
  if (!category) navigate('/auth/404');
  const answer = category.list.find((v) => v.question === question).answer;
  return answer;
}
