import { useNavigate } from '@hooks/useNavigate';
import { useEvent } from 'react-use';

export function useHandleLinkClick() {
  const navigate = useNavigate();

  const onClick = (e) => {
    const target = e.target;
    const isTargetLink = e.target.tagName === 'A';
    const isInnerLink = target.classList.contains('app-inner-link');
    if (!isTargetLink || !isInnerLink) return;

    e.preventDefault();
    navigate(target.getAttribute('href'));
  };

  useEvent('click', onClick);
}
