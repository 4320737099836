import { QAnswerBackButton } from '@widgets/questions-answers/specific/QAnswerBackButton';
import { QASpecificAnswer } from '@widgets/questions-answers/specific/QASpecificAnswer';
import { QASpecificOtherQuestions } from '@widgets/questions-answers/specific/QASpecificOtherQuestions';
import { QASpecificProvider } from '@widgets/questions-answers/specific/QASpecificProvider';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface QASpecificPageProps {
  id: string;
  location: any;
}

const QASpecificPage: React.FC<QASpecificPageProps> = ({ id: question, location }) => {
  const { t } = useTranslation();

  return (
    <QASpecificProvider question={question}>
      <div className="pt-6 pb-12 lg:pt-12 xl:pb-52">
        <div className="container_xl">
          <QAnswerBackButton location={location} />
          <h1 className="mt-4 text-2xl lg:mt-6 lg:text-4xl">{t(question)}</h1>
          <div className="mt-8 lg:mt-16 lg:flex lg:gap-x-8">
            <QASpecificAnswer />
            <QASpecificOtherQuestions />
          </div>
        </div>
      </div>
    </QASpecificProvider>
  );
};

export default QASpecificPage;
